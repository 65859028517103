import React, { useEffect, useState, useMemo, useCallback } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import queryString from 'query-string'
import axios from 'axios'
import { useLocation } from '@reach/router'
import Layout from '../layouts/Layout'
import CONSTANTS from '../utils/constants'

const stripePromise = loadStripe(
	process.env.GATSBY_IS_PROD
		? 'pk_live_51I7KSyEp8jFVuJ32VsKNYckSLgssRZjq9DT9qPOtNDbC86R97GDhmVSMzAkvRdqRdFKYxb64Q1V7DU9i3BmS2vr600zuQUksmc'
		: 'pk_test_51I7KSyEp8jFVuJ32S07YkyplBZAmXH3utZPtUpRnUXPZT075a3klZNbMqr3S17ycsdV6UZLAFR9hkjgFhzu7EPOO0074uMstjN',
)

const getWebsiteUrl = () => {
	const windowLocation = window.location.toString()

	return windowLocation.startsWith('http://localhost:')
		? `http://localhost:8000`
		: `https://${process.env.GATSBY_HOSTNAME}`
}
const getFuretUrl = () => {
	const windowLocation = window.location.toString()

	return windowLocation.startsWith('http://localhost:')
		? `http://localhost:3000`
		: windowLocation.startsWith(`https://dev.selfcity.fr`)
		? `https://staging.belett.selfcity.fr`
		: `https://belett.selfcity.fr`
}

export default () => {
	const [startedLoading, setStartedLoading] = useState(false)
	const [error, setError] = useState(null)
	const location = useLocation()
	const queryParams = useMemo(() => {
		return location.search ? queryString.parse(location.search) : {}
	}, [location.search])
	const redirectTocheckout = useCallback(
		async ({ stripePriceId, invoice_number }) => {
			// When the customer clicks on the button, redirect them to Checkout.
			const stripe = await stripePromise
			const successUrl = `${getWebsiteUrl()}/payment?success=true&invoice_number=${invoice_number}`
			const cancelUrl = `${getWebsiteUrl()}/payment?error=true&invoice_number=${invoice_number}`

			stripe
				.redirectToCheckout({
					lineItems: [
						{
							price: stripePriceId, // Replace with the ID of your price
							quantity: 1,
						},
					],
					mode: 'payment',
					cancelUrl,
					successUrl,
				})
				.catch((err) => {
					console.error(error)
					setError(err)
				})
			// If `redirectToCheckout` fails due to a browser or network
			// error, display the localized error message to your customer
			// using `error.message`.
		},
		[],
	)

	useEffect(() => {
		if (!startedLoading && queryParams.invoiceId) {
			setStartedLoading(true)
			axios
				.get(
					`${getFuretUrl()}/api/v1/stripe/prices/?invoiceId=${
						queryParams.invoiceId
					}`,
				)
				.then((res) => {
					redirectTocheckout(res.data.data)
				})
				.catch((err) => {
					console.error(err)
				})
		}
	}, [location, startedLoading, queryParams])

	return (
		<Layout reference={CONSTANTS.PAGE_REFERENCES.PAYMENT}>
			<main className="style-2020-04 page-home">
				{queryParams.invoiceId ? (
					<div className="flex flex-nowrap flex-column flex-center section-title">
						<p className="m-t-5">
							Redirection vers le module de paiement sécurisé...
						</p>
					</div>
				) : queryParams.success ? (
					<div
						className="flex flex-nowrap flex-column flex-center section-title"
						style={{
							textAlign: 'center',
							letterSpacing: 1,
							minHeight: 300,
						}}
					>
						<div style={{ maxWidth: 700 }}>
							<p className="m-t-3 m-b-3 text-bolder">
								<span role="img" aria-label="Merci">
									🙏
								</span>{' '}
								Merci pour votre confiance !{' '}
								<span role="img" aria-label="Merci">
									🙏
								</span>
							</p>
							<p className="m-b-3">
								Votre paiement pour la facture{' '}
								<span className="text-bolder">
									N°{queryParams.invoice_number}
								</span>{' '}
								a bien été pris en compte.
							</p>
							<p className="dark-green text-bolder">
								L'équipe Selfcity
							</p>
						</div>
					</div>
				) : error ? (
					<div
						className="flex flex-nowrap flex-column flex-center section-title"
						style={{
							textAlign: 'center',
							letterSpacing: 1,
							minHeight: 300,
						}}
					>
						<div style={{ maxWidth: 700 }}>
							<p className="m-t-3 m-b-3 text-bolder">
								Oups ! Une erreur est survenue.
							</p>
							<p className="m-b-3">
								Nous vous invitons à réessayer dans quelques
								instants et à bien vérifier les informations
								saisies.
								<br />
								Si le problème persiste, merci de vous
								rapprocher de notre service client.
							</p>
							<p className="dark-green text-bolder">
								L'équipe Selfcity
							</p>
						</div>
					</div>
				) : (
					<div className="flex flex-nowrap flex-column flex-center section-title">
						Chargement . . .
					</div>
				)}
			</main>
		</Layout>
	)
}
